<template>
  <div class="section">
    <div v-if="property" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Rate Plans') }}
          </h2>
        </div>
        <div v-permission="['add-rate']" class="column is-narrow">
          <Modal ref="modal" :mask="true">
            <template #default="{ hide }">
              <Form
                :property="property"
                :value="selected"
                @close="
                  () => {
                    hide();
                    fetchData();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add Plan') }}</a>
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #name="{ record }">
          {{ $t(record.name) }}
        </template>
        <template #code="{ record }">
          {{ record.code }}
        </template>
        <template #neppanCode="{ record }">
          {{ record.neppanCode || '-' }}
        </template>
        <template #action="{ record }">
          <Delete
            v-permission="['modify-rate']"
            :record="record"
            @delete="fetchData"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n src="@/locales/components/rate-plans.json"></i18n>
<i18n>
{
  "en": {
    "Add Plan": "Add Plan",
  },
  "ja": {
    "Add Plan": "プランを追加",
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import pagination from '@/components/mixins/pagination';
import Form from '@/views/properties/components/rate-plans/Form';
import Delete from '@/views/properties/components/rate-plans/Delete';

export default {
  name: 'PropertyRatePlans',
  components: {
    Form,
    Modal,
    Delete,
  },
  mixins: [pagination],
  data() {
    return {
      list: undefined,
      isFetching: false,
      selected: undefined,
      columns: [
        {
          title: '#',
          slots: { customRender: '_id' },
          customRender: ({ index }) => ({
            children: index + 1,
          }),
        },
        {
          title: this.$t('Name'),
          dataIndex: 'name',
          key: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Code'),
          dataIndex: 'code',
          key: 'code',
          slots: { customRender: 'code' },
        },
        {
          title: this.$t('Neppan Code'),
          dataIndex: 'neppanCode',
          key: 'neppanCode',
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    ...mapState('properties', ['property']),
  },
  watch: {
    $route(route) {
      if (route.name === 'property-rate-plans') {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      this.fetchData();
    },
    async fetchData() {
      this.isFetching = true;

      const query = {
        ...this.$route.query,
        propertyId: this.$route.params.id,
      };

      return this.$store
        .dispatch('rms-rate-plans/list', query)
        .then((resp) => {
          const { data, pagination } = resp;
          this.list = data;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.selected = record;
          this.$refs.modal.show();
        },
      };
    },
  },
};
</script>

<style scoped></style>
