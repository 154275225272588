<template>
  <div class="section">
    <div v-if="property" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Housekeeping Cycles') }}
          </h2>
        </div>
        <div v-permission="['add-housekeeping-cycle']" class="column is-narrow">
          <Modal>
            <template #default="{ hide }">
              <Form
                :property="property"
                @close="
                  () => {
                    hide();
                    fetchData();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add Cycle') }}</a>
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #type="{ record }">
          {{ $t(record.type) }}
        </template>
        <template #threshold="{ record }">
          {{ record.threshold ? $t(record.threshold) : '-' }}
        </template>
        <template #every="{ record }">
          {{ record.every || '-' }}
        </template>
        <template #active="{ record }">
          <a-switch
            v-model:checked="record.active"
            @change="(status) => { updateActiveStatus(status, record); }"
          />
        </template>
        <template #action="{ record }">
          <Delete
            v-permission="['modify-housekeeping-cycle']"
            :record="record"
            @delete="fetchData"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n src="@/locales/components/housekeeping-cycles.json"></i18n>
<i18n>
{
  "en": {
    "Add Cycle": "Add Cycle",
  },
  "ja": {
    "Add Cycle": "サイクルを追加",
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import { getResponseError } from '@/utils/util';
import pagination from '@/components/mixins/pagination';
import Form from '@/views/properties/components/housekeeping-cycles/Form';
import Delete from '@/views/properties/components/housekeeping-cycles/Delete';

export default {
  name: 'PropertyHousekeepingCycles',
  components: {
    Form,
    Modal,
    Delete,
  },
  mixins: [pagination],
  data() {
    return {
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: this.$t('Type'),
          dataIndex: 'type',
          key: 'type',
          slots: { customRender: 'type' },
        },
        {
          title: this.$t('Above/Below'),
          dataIndex: 'threshold',
          key: 'threshold',
          slots: { customRender: 'threshold' },
        },
        {
          title: this.$t('No. of Nights'),
          dataIndex: 'nights',
          key: 'nights',
        },
        {
          title: this.$t('Every'),
          dataIndex: 'every',
          key: 'every',
          slots: { customRender: 'every' },
        },
        {
          title: this.$t('Status'),
          dataIndex: 'active',
          key: 'active',
          slots: { customRender: 'active' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    ...mapState('properties', ['property']),
  },
  watch: {
    $route(route) {
      if (route.name === 'property-housekeeping-cycles') {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      this.fetchData();
    },
    async fetchData() {
      this.isFetching = true;

      const query = {
        ...this.$route.query,
        propertyId: this.$route.params.id,
      };

      return this.$store
        .dispatch('housekeeping-cycles/list', query)
        .then((resp) => {
          const { list, pagination } = resp;
          this.list = list;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    async updateActiveStatus(isActive, record) {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');

        const params = { id: record.id, active: Boolean(isActive) };

        await this.$store.dispatch('housekeeping-cycles/setActive', params);
        await this.fetchData();
      } catch (err) {
        record.active = !record.active;
        this.$message.error(getResponseError(err), 3);
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    rowClicked(record) {
      return {
        onClick: () => record,
      };
    },
  },
};
</script>

<style scoped></style>
