<template>
  <div class="section">
    <div v-if="property" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Property Owners') }}
          </h2>
        </div>
        <div v-permission="['modify-partner']" class="column is-narrow">
          <Modal>
            <template #default="{ hide }">
              <AddOwnerForm
                :property="property"
                :owner-list="ownerList"
                @close="
                  () => {
                    hide();
                    fetchData();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add Owner') }}</a>
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        row-key="_id"
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #email="{ record }">
          {{
            record.personsInCharge && record.personsInCharge.length > 0
              ? record.personsInCharge[0].email
              : '-'
          }}
        </template>
        <template #action="{ record }">
          <DelOwner
            v-permission="['modify-partner']"
            :record="{ ...record, property }"
            @delete="fetchData"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Property Owners": "Property Owners",
    "Add Owner": "Add Owner",
    "Property Owner": "Property Owner",
    "Email": "Email",
  },
  "ja": {
    "Property Owners": "物件所有者",
    "Add Owner": "所有者追加",
    "Property Owner": "物件所有者",
    "Email": "メールアドレス",
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import AddOwnerForm from '@/views/properties/components/AddOwnerForm';
import DelOwner from '@/views/properties/components/DelOwner';

export default {
  name: 'PropertyOwners',
  components: {
    AddOwnerForm,
    Modal,
    DelOwner,
  },
  data() {
    return {
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Property Owner'),
          dataIndex: 'name',
          slots: 'name',
        },
        {
          title: this.$t('Email'),
          dataIndex: 'email',
          key: 'email',
          slots: { customRender: 'email' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    ...mapState('properties', ['property']),
  },
  watch: {
    $route(route) {
      if (route.name === 'property-owners') {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
    this.getOwners();
  },
  methods: {
    getOwners() {
      this.$store.dispatch('partners/list', { relationship: 'PO', infinite: true }).then((res) => {
        this.ownerList = res.list;
      });
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      const query = {
        relationship: 'PO',
        propertyId: this.property.newPropertyCode,
        pageSize: 100,
      };

      this.$store
        .dispatch('partners/list', query)
        .then((response) => {
          this.list = response.list.map((p, index) => {
            p._id = index + 1;

            return p;
          });
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    rowClicked(record) {
      return {
        onClick: () => record,
      };
    },
  },
};
</script>

<style scoped></style>
