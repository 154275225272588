<template>
  <div class="section">
    <div v-if="property" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Room Type') }}
          </h2>
        </div>
        <div v-permission="['add-property']" class="column is-narrow">
          <Modal ref="roomTypeModal">
            <template #default="{ hide }">
              <RoomTypeForm
                :property="property"
                :room-type="selectedRecord"
                :is-submitting="isSubmitting"
                @submit="handleSubmit"
                @delete="handleDelete"
                @close="
                  () => {
                    hide();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler>
              <a
                class="ant-btn ant-btn-lg ant-btn-primary"
                @click.prevent="showAddModal"
              >
                {{ $t('Add Room Type') }}
              </a>
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="roomTypes"
        :custom-row="rowClicked"
      >
        <template #name="{ record }">
          {{ record.name }}
        </template>
        <template #neppanRoomType="{ record }">
          {{ getNeppanRoomTypeName(record.neppanRoomType) }}
        </template>
        <template #numOfRoom="{ record }">
          {{ record.rooms.length }}
        </template>
        <template #status="{ record }">
          <div @click.stop>
            <a-switch
              v-model:checked="record.isActive"
              @change="(status) => { handleStatusChange(status, record); }"
            />
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Add Room Type": "Add Room Type",
    "Room Name": "Room Name",
    "Room Type": "Room Type",
    "Capacity": "Capacity",
    "Total Beds": "Total Beds",
    "Status": "Status",
    "success": "Room type has been saved",
    "removed": "Room type has been removed"
  },
  "ja": {
    "Add New Room": "部屋追加",
    "Room Name": "部屋番号",
    "Room Type": "部屋タイプ",
    "Capacity": "収容人数",
    "Total Beds": "総Bed数",
    "Status": "ステータス",
    "success": "Room type has been saved",
    "removed": "Room type has been removed"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import checkPermission from '@/utils/permission';
import pagination from '@/components/mixins/pagination';
import Modal from '@/components/Modal';
import RoomTypeForm from '@/views/properties/components/RoomTypeForm';

export default {
  name: 'PropertyRoomType',
  components: {
    RoomTypeForm,
    Modal,
  },
  mixins: [pagination],
  data() {
    return {
      selectedRecord: undefined,
      roomTypes: undefined,
      isFetching: false,
      isSubmitting: false,
      neppanRoomTypes: undefined,
    };
  },
  computed: {
    ...mapState('properties', ['property', 'loading']),
    columns() {
      const columns = [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Room Type Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Neppan Room Type'),
          dataIndex: 'neppanRoomType',
          key: 'neppanRoomType',
          slots: { customRender: 'neppanRoomType' },
        },
        {
          title: this.$t('Number of rooms'),
          dataIndex: 'numOfRoom',
          key: 'numOfRoom',
          slots: { customRender: 'numOfRoom' },
        },
      ];

      if (checkPermission(['modify-room'])) {
        columns.push({
          title: this.$t('Status'),
          key: 'isActive',
          dataIndex: 'isActive',
          slots: { customRender: 'status' },
        });
      }

      return columns;
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'property-room') {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
    this.fethcNeppanRoomType();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch(
          'room-types/list',
          { ...this.$route.query, propertyId: this.property.newPropertyCode },
        )
        .then((resp) => {
          const list = resp;

          this.roomTypes = list.map((item, idx) => ({ _id: idx + 1, ...item }));
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.selectedRecord = record;
          this.$refs.roomTypeModal.show();
        },
      };
    },
    showAddModal() {
      this.selectedRecord = undefined;
      this.$refs.roomTypeModal.show();
    },
    handleSubmit(data) {
      this.isSubmitting = true;
      const action = data.id ? 'room-types/update' : 'room-types/add';
      this.$store.dispatch(action, data).then(() => {
        this.isSubmitting = false;
        this.$refs.roomTypeModal.hide();
        this.$message.success(this.$t('success'));
        this.fetchData();
      }).catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.error = err.response.data.errors.message;
        }
        this.isSubmitting = false;
      });
    },
    handleStatusChange(status, record) {
      const payload = {
        id: record.id,
        roomIds: record.rooms.map((r) => r.id),
        isActive: status,
      };
      this.$store.dispatch('room-types/update', payload)
        .then(() => {
          this.$message.success(this.$t('success'));
          this.fetchData();
        });
    },
    handleDelete(id) {
      this.$store.dispatch('room-types/del', id).then(() => {
        this.$refs.roomTypeModal.hide();
        this.$message.success(this.$t('removed'));
        this.fetchData();
      });
    },
    fethcNeppanRoomType() {
      const params = {
        propertyId: this.property.newPropertyCode,
      };
      this.$store.dispatch('rooms/listNeppanRoomTypes', params).then((res) => {
        this.neppanRoomTypes = res.rooms || [];
      });
    },
    getNeppanRoomTypeName(code) {
      const type = (this.neppanRoomTypes || []).find((e) => code === e.roomTypeGroupCode);
      if (type) {
        return type.roomTypeGroupName;
      }

      return '';
    },
  },
};
</script>

<style>

</style>
