<template>
  <div class="section">
    <div v-if="property" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Room') }}
          </h2>
        </div>
        <div v-permission="['add-property']" class="column is-narrow">
          <router-link
            class="ant-btn ant-btn-lg ant-btn-primary"
            :to="{ name: 'property-room-add' }"
          >
            {{ $t('Add New Room') }}
          </router-link>
        </div>
      </div>
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="rooms"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #name="{ record }">
          {{ record.name }}
        </template>
        <template #type="{ record }">
          {{ record.type }}
        </template>

        <template #status="{ record }">
          <div @click.stop>
            <a-switch
              v-model:checked="record.isActive"
              @change="(status) => { updateStatus(status, record); }"
            />
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Room": "Room",
    "Add New Room": "Add New Room",
    "Room Name": "Room Name",
    "Room Type": "Room Type",
    "Capacity": "Capacity",
    "Total Beds": "Total Beds",
    "Status": "Status",
  },
  "ja": {
    "Room": "部屋",
    "Add New Room": "部屋追加",
    "Room Name": "部屋番号",
    "Room Type": "部屋タイプ",
    "Capacity": "収容人数",
    "Total Beds": "総Bed数",
    "Status": "ステータス",
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import checkPermission from '@/utils/permission';
import pagination from '@/components/mixins/pagination';

export default {
  name: 'PropertyRoom',
  mixins: [pagination],
  data() {
    return {
      rooms: undefined,
      isFetching: false,
    };
  },
  computed: {
    ...mapState('properties', ['property', 'loading']),
    columns() {
      const columns = [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Room Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Room Type'),
          dataIndex: 'type',
          key: 'type',
          slots: { customRender: 'type' },
        },
        {
          title: this.$t('Capacity'),
          dataIndex: 'capacity',
          key: 'capacity',
          slots: { customRender: 'capacity' },
        },
        {
          title: this.$t('Total Beds'),
          dataIndex: 'totalBeds',
          key: 'totalBeds',
          slots: { customRender: 'totalBeds' },
        },
      ];

      if (checkPermission(['modify-room'])) {
        columns.push({
          title: this.$t('Status'),
          key: 'isActive',
          dataIndex: 'isActive',
          slots: { customRender: 'status' },
        });
      }

      return columns;
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'property-room') {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('rooms/list', { ...this.$route.query, propertyId: this.property.newPropertyCode })
        .then((resp) => {
          const { list, pagination } = resp;

          this.rooms = list.map((item, idx) => ({ _id: idx + 1, ...item }));
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    updateStatus(status, record) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      // eslint-disable-next-line no-unused-vars
      const { _id, ...rest } = record;
      this.$store.dispatch('rooms/update', { id: record.id, isActive: status }).finally(() => {
        this.fetchData();
      });
    },
    // eslint-disable-next-line no-unused-vars
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({
            name: 'room-overview',
            params: { id: this.$route.params.id, rid: record.id },
          });
        },
      };
    },
  },
};
</script>

<style scoped></style>
