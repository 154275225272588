<template>
  <div class="section">
    <div v-if="property" class="container">
      <div class="columns is-large-gap">
        <div class="column is-6">
          <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
            {{ $t('log-and-preferences') }}
          </h2>
          <div
            v-role="ROLES.INTERNAL"
            class="columns columns-table is-gapless is-mobile is-size-7 m-b-0"
          >
            <div class="column">
              <div class="th">
                {{ $t('Log saved period') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditLogPreferencesForm
                      :value="property"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div
            v-role="ROLES.INTERNAL"
            class="columns columns-table is-gapless is-multiline is-size-7 m-b-0"
          >
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Inspection log picture saved period') }}
                </div>
                <div>{{ imageRetention.inspections }} {{ $t('month(s)') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Incident log picture saved period') }}
                </div>
                <div>{{ imageRetention.incidents }} {{ $t('month(s)') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('lost and found log picture saved period') }}
                </div>
                <div>{{ imageRetention.lostAndFound }} {{ $t('month(s)') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Response log picture saved period') }}
                </div>
                <div>{{ imageRetention.guestResponse }} {{ $t('month(s)') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('out of order log picture saved period') }}
                </div>
                <div>{{ imageRetention.outOfOrder }} {{ $t('month(s)') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Alert log picture saved period') }}
                </div>
                <div>{{ imageRetention.alerts }} {{ $t('month(s)') }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Log') }}
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Created on') }}
                </div>
                <div>{{ $filters.date(property.createdAt, { format: 'MM/DD/YYYY HH:mm' }) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Updated on') }}
                </div>
                <div>{{ $filters.date(property.updatedAt, { format: 'MM/DD/YYYY HH:mm' }) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Created by') }}
                </div>
                <div>{{ property.createdByUser.email }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Updated by') }}
                </div>
                <div>{{ property.updatedByUser.email }}</div>
              </div>
            </div>
          </div>
          <div
            v-role="ROLES.INTERNAL"
            class="columns columns-table is-gapless is-mobile is-size-7 m-b-0"
          >
            <div class="column">
              <div class="th">
                {{ $t('Sender email') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <SenderForm
                      :value="sender"
                      :property="property"
                      @close="() => {
                        hide();
                        fetchSender();
                      }"
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sender email address') }}
                </div>
                <div>{{ sender.email || $t('Not set') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sender email name') }}
                </div>
                <div>{{ sender.name || $t('Not set') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-permission="['modify-property']" class="column is-6">
          <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
            {{ $t('Permission') }}
          </h2>
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Property status') }}
              </div>
            </div>
            <div class="column is-narrow">
              <a-switch v-model:checked="status" :loading="submitting" />
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Use room type') }}
              </div>
            </div>
            <div class="column is-narrow">
              <a-switch v-model:checked="useRoomType" :loading="submitting" :disabled="true" />
            </div>
          </div>
          <Services :services="property.services" />
          <hr>
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-danger has-text-weight-bold">
                {{ $t('Delete property') }}
              </div>
              <div>{{ $t('del-property-desc') }}</div>
            </div>
            <div class="column is-narrow">
              <DelProperty :record="property" @delete="$router.push({ path: '/properties' })">
                <template #handler="{ show }">
                  <a-button type="danger" @click="show">
                    {{ $t('Delete') }}
                  </a-button>
                </template>
              </DelProperty>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <div
                class="columns columns-table is-gapless is-mobile is-size-7 m-b-0"
              >
                <div class="column">
                  <div class="th">
                    {{ $t('Preferences') }}
                  </div>
                </div>
                <div class="column has-text-right">
                  <div class="th">
                    <Modal>
                      <template #default="{ hide }">
                        <EditPropertyPreferencesForm
                          :value="property"
                          @close="
                            () => {
                              hide();
                              asyncData();
                            }
                          "
                          @cancel="hide"
                        />
                      </template>
                      <template #handler="{ show }">
                        <a class="has-text-white" @click.prevent="show">
                          <IconBase width="13" height="13" icon-name="edit">
                            <IconEdit />
                          </IconBase>
                          {{ $t('Edit') }}</a>
                      </template>
                    </Modal>
                  </div>
                </div>
              </div>
              <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
                <div class="column is-6">
                  <div class="td">
                    <div class="has-text-primary has-text-weight-bold">
                      {{ $t('Price auto reflection flag') }}
                    </div>
                    <div>{{ priceAutoReflection }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UseRoomTypeWarning ref="useRoomTypeWarning" />
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "log-and-preferences": "Log & Preferences",
    "Log saved period": "Log saved period",
    "Edit": "Edit",
    "Inspection log picture saved period": "Inspection log picture saved period",
    "month(s)": "month(s)",
    "Incident log picture saved period": "Incident log picture saved period",
    "lost and found log picture saved period": "Lost and found log picture saved period",
    "Guest Response log picture saved period": "Guest Response log picture saved period",
    "out of order log picture saved period": "Out of order log picture saved period",
    "Alert log picture saved period": "Alert log picture saved period",
    "Log": "Log",
    "Created on": "Created on",
    "Updated on": "Updated on",
    "Created by": "Created by",
    "Updated by": "Updated by",
    "Permission": "Permission",
    "Property status": "Property status",
    "Delete property": "Delete property",
    "del-property-desc": "Once deleted, you cannot recover this property. Please be certain.",
    "Delete": "Delete",
    "Use room type": "Use room type",
    "perPerson": "Per person",
    "perRoom": "Per room"
  },
  "ja": {
    "log-and-preferences": "ログと環境設定",
    "Log saved period": "ログの保管期間",
    "Edit": "編集する",
    "Inspection log picture saved period": "確認箇所リスト画像保管期間",
    "month(s)": "月",
    "Incident log picture saved period": "重大な設備不備画像保管期間",
    "lost and found log picture saved period": "忘れ物画像保管期間",
    "Guest Response log picture saved period": "ゲスト反応画像保管期間",
    "out of order log picture saved period": "使用不可画像保管期間",
    "Alert log picture saved period": "アラート画像保管期間",
    "Log": "ログ",
    "Created on": "作成日時",
    "Updated on": "更新日時",
    "Created by": "作成者",
    "Updated by": "更新者",
    "Permission": "許可",
    "Property status": "物件ステータス",
    "Delete property": "物件削除",
    "del-property-desc": "一度削除すると復旧することはできません。必ず確認してください。",
    "Delete": "削除",
    "Use room type": "Use room type",
    "perPerson": "Per person",
    "perRoom": "Per room"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import IconEdit from '@/components/icons/IconEdit';
import roles from '@/components/mixins/roles';
import DelProperty from '@/views/properties/components/DelProperty';
import EditLogPreferencesForm from '@/views/properties/components/EditLogPreferencesForm';
import SenderForm from '@/views/properties/components/SenderForm';
import EditPropertyPreferencesForm from '@/views/properties/components/EditPropertyPreferencesForm';
import UseRoomTypeWarning from '@/views/properties/components/UseRoomTypeWarning';
import { getResponseError } from '@/utils/util';

export default {
  name: 'PropertyOverview',
  components: {
    DelProperty,
    EditLogPreferencesForm,
    Modal,
    IconEdit,
    SenderForm,
    EditPropertyPreferencesForm,
    UseRoomTypeWarning,
  },
  mixins: [roles],
  data() {
    return {
      submitting: false,
      sender: {
        email: undefined,
        name: undefined,
        propertyId: undefined,
      },
    };
  },
  computed: {
    ...mapState('properties', ['property', 'loading']),
    status: {
      get() {
        return this.property.isActive;
      },
      set(val) {
        this.toggleFlagUpdate(val, 'isActive');
      },
    },
    useRoomType: {
      get() {
        return this.property.useRoomType;
      },
      set(val) {
        this.toggleFlagUpdate(val, 'useRoomType');
      },
    },
    imageRetention() {
      return this.property.imageRetention || {};
    },
    priceAutoReflection() {
      const { priceAutoReflection } = this.property;
      const labels = {
        room: this.$t('perRoom'),
        person: this.$t('perPerson'),
      };

      return labels[priceAutoReflection] || '-';
    },
  },
  created() {
    this.fetchSender();
  },
  methods: {
    asyncData() {
      this.$store.dispatch('properties/get', { id: this.$route.params.id });
    },
    fetchSender() {
      const query = {
        propertyId: this.$route.params.id,
      };
      this.$store.dispatch('guest-email/getSender', query)
        .then((res) => {
          this.sender = res;
        });
    },
    /**
     * @param {Boolean} value
     * @param {'isActive'|'useRoomType'} field
     */
    async toggleFlagUpdate(value, field = 'isActive') {
      const dispatchMethod = {
        isActive: 'properties/update',
        useRoomType: 'properties/useRoomType',
      };

      this.$store.commit('properties/SET_PROPERTY', {
        ...this.property,
        [field]: value,
      });

      this.submitting = true;

      try {
        const { hasRoomWithoutType } = await this.$store.dispatch(dispatchMethod[field], {
          id: this.property.newPropertyCode,
          [field]: Boolean(value),
        });

        if (hasRoomWithoutType === true && field === 'useRoomType') {
          this.$refs.useRoomTypeWarning.show();
        }

        this.submitting = false;
      } catch (error) {
        this.$store.commit('properties/SET_PROPERTY', {
          ...this.property,
          [field]: !value,
        });

        this.submitting = false;
        this.$message.error(getResponseError(error));
      }
    },
  },
};
</script>

<style scoped></style>
