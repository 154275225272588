<template>
  <div class="section">
    <div v-if="property" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Out of Order') }}
          </h2>
        </div>
      </div>
      <div class="columns is-mobi">
        <div class="column">
          <SearchForm :property="property" @filter="fetchPropertyData" />
        </div>
        <div v-permission="['add-out-of-order']" class="column is-narrow">
          <Modal>
            <template #default="{ hide }">
              <AddOutOfOrderForm
                :property="property"
                @close="
                  () => {
                    hide();
                    fetchPropertyData();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add Out of Order') }}</a
              >
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #reportedTime="{ record }">
          {{ $filters.date(record.createdAt, { format: $variables.datetimeFormat }) }}
        </template>
        <template #name="{ record }">
          {{ record.name }}
        </template>
        <template #room="{ record }">
          {{ record.room ? record.room.name : '' }}
        </template>
        <template #description="{ record }">
          {{ record.description }}
        </template>
        <template #status="{ record }">
          {{ record.status }}
        </template>
        <template #action="{ record }">
          <DelOutOfOrder
            v-permission="['modify-out-of-order']"
            :record="record"
            @delete="fetchPropertyData"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Out of Order": "Out of Order",
"Add Out of Order": "Add Out of Order",
"Reported time": "Reported time",
"Out of Order Name": "Out of Order Name",
"Room Name": "Room Name",
"Description": "Description",
"Status": "Status",
"Delete out of order": "Delete out of order",
"delete-desc": "Are you sure you want to delete out of order {name} ?",
"Delete": "Delete",
"Cancel": "Cancel"
},
"ja": {
"Out of Order": "使用不可",
"Add Out of Order": "使用不可を追加",
"Reported time": "報告時間",
"Out of Order Name": "使用不可 アイテム名",
"Room Name": "部屋番号",
"Description": "詳細",
"Status": "ステータス",
"Delete out of order": "使用不可を削除",
"delete-desc": "本当に {name} を削除しますか？",
"Delete": "削除",
"Cancel": "取り消し"
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import SearchForm from '@/views/out-of-order/components/SearchForm';
import AddOutOfOrderForm from '@/views/properties/components/AddOutOfOrderForm';
import DelOutOfOrder from '@/views/out-of-order/components/DelOutOfOrder';
import list from '@/views/out-of-order/mixins/list';

export default {
  name: 'PropertyOutOfOrder',
  components: {
    Modal,
    SearchForm,
    AddOutOfOrderForm,
    DelOutOfOrder,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Reported time'),
          dataIndex: 'reportedTime',
          slots: { customRender: 'reportedTime' },
        },
        {
          title: this.$t('Out of Order Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Room Name'),
          dataIndex: 'room',
          key: 'room',
          slots: { customRender: 'room' },
        },
        {
          title: this.$t('Description'),
          dataIndex: 'description',
          key: 'description',
          slots: { customRender: 'description' },
        },
        {
          title: this.$t('Status'),
          key: 'status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    ...mapState('properties', ['property', 'loading']),
  },
  created() {
    this.fetchPropertyData();
  },
  methods: {
    fetchPropertyData() {
      return this.fetchData({ propertyId: this.property.newPropertyCode });
    },
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchPropertyData();
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({ name: 'out-of-order-edit', params: { id: record.id } });
        },
      };
    },
  },
};
</script>

<style scoped></style>
