<template>
  <div class="user">
    <template v-if="!loading">
      <AdminSubMenu v-if="property" :menu="menu" :sub-menu="subMenu">
        {{ property.name }}
      </AdminSubMenu>
      <router-view />
    </template>
  </div>
</template>

<i18n>
{
  "en": {
    "Overview": "Overview",
    "Property": "Property",
    "Room": "Room",
    "Room Type": "Room Type",
    "Out of Order": "Out of Order",
    "Incidents": "Incidents",
    "Facilities": "Facilities",
    "Owners": "Owners",
    "Housekeeping Cycles": "Housekeeping Cycles",
    "Rate Plans": "Rate Plans",
  },
  "ja": {
    "Overview": "概要",
    "Property": "物件",
    "Room": "部屋",
    "Out of Order": "使用不可",
    "Incidents": "重大な設備不備",
    "Facilities": "設備",
    "Owners": "オーナー",
    "Housekeeping Cycles": "中間清掃設定",
    "Rate Plans": "Rate Plans",
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import checkRole from '@/utils/role';
import checkPermission from '@/utils/permission';
import roles from '@/components/mixins/roles';
import previousRoute from '@/components/mixins/previousRoute';
import AdminSubMenu from '@/components/AdminSubMenu';

export default {
  name: 'Property',
  components: { AdminSubMenu },
  mixins: [roles, previousRoute],
  data() {
    return {
      menuName: 'properties',
    };
  },
  computed: {
    menu() {
      return {
        query: this.previousRouteQuery,
        name: this.menuName,
        title: this.$t('Back'),
      };
    },
    subMenu() {
      const menu = [
        {
          name: 'property-overview',
          title: this.$t('Overview'),
        },
        {
          name: 'property-property',
          title: this.$t('Property'),
        },
        {
          name: 'property-rooms',
          title: this.$t('Room'),
        },
        {
          name: 'property-room-type',
          title: this.$t('Room Type'),
        },
      ];

      if (checkPermission(['view-out-of-order'])) {
        menu.push({
          name: 'property-out-of-order',
          title: this.$t('Out of Order'),
        });
      }

      if (checkPermission(['view-incident'])) {
        menu.push({
          name: 'property-incident',
          title: this.$t('Incidents'),
        });
      }

      if (checkRole(this.ROLES.INTERNAL)) {
        menu.push({
          name: 'property-facilities',
          title: this.$t('Facilities'),
        });
      }

      if (checkPermission(['view-partner'])) {
        menu.push({
          name: 'property-owners',
          title: this.$t('Owners'),
        });
      }

      if (checkPermission(['view-housekeeping-cycle'])) {
        menu.push({
          name: 'property-housekeeping-cycles',
          title: this.$t('Housekeeping Cycles'),
        });
      }

      if (checkPermission(['view-rate'])) {
        menu.push({
          name: 'property-rate-plans',
          title: this.$t('Rate Plans'),
        });
      }

      return menu;
    },
    childrenRoutes() {
      return [
        'property-overview',
        'property-property',
        'property-rooms',
        'property-room-type',
        'out-of-order',
        'property-incident',
        'property-facilities',
        'property-owners',
        'property-housekeeping-cycles',
      ];
    },
    ...mapState('properties', ['property', 'loading']),
  },
  created() {
    this.asyncData();
  },
  methods: {
    async asyncData(route = null) {
      route = route || this.$route;
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        this.loading = true;
        this.property = await this.$store.dispatch('properties/get', { id: route.params.id });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
  },
};
</script>

<style scoped></style>
