<template>
  <div class="section">
    <div v-if="property" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Facility') }}
          </h2>
        </div>
        <div v-permission="['add-property']" class="column is-narrow">
          <Modal>
            <template #default="{ hide }">
              <AddFacilityForm
                :partner-list="partnerList"
                :property="property"
                @close="
                  () => {
                    hide();
                    fetchData();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add NEW Facility') }}
              </a>
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        row-key="id"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #name="{ record }">
          {{ record.name }}
        </template>
        <template #category="{ record }">
          {{ record.category }}
        </template>
        <template #subCategory="{ record }">
          {{ record.subCategory }}
        </template>

        <template #description="{ record }">
          {{ record.description }}
        </template>

        <template #action="{ record }">
          <DelFacility v-permission="['modify-facility']" :record="record" @delete="fetchData" />
        </template>
      </a-table>
      <Modal ref="editModel">
        <template #default="{ hide }">
          <AddFacilityForm
            :partner-list="partnerList"
            :facility="selectedFacility"
            @close="
              () => {
                hide();
                fetchData();
              }
            "
            @cancel="hide"
          />
        </template>
      </Modal>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Facility": "Facility",
"Add NEW Facility": "Add NEW Facility",
"Facility Name": "Facility Name",
"Category": "Category",
"Sub Category": "Sub Category",
"Description": "Description",
},
"ja": {
"Facility": "設備",
"Add NEW Facility": "新規設備追加",
"Facility Name": "設備名",
"Category": "大区分",
"Sub Category": "小区分",
"Description": "詳細",
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import AddFacilityForm from '@/views/properties/components/AddFacilityForm';
import DelFacility from '@/views/properties/components/DelFacility';

export default {
  name: 'PropertyFacilities',
  components: {
    AddFacilityForm,
    Modal,
    DelFacility,
  },
  data() {
    return {
      submitting: false,
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Facility Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Category'),
          dataIndex: 'category',
          slots: { customRender: 'category' },
        },
        {
          title: this.$t('Sub Category'),
          dataIndex: 'subCategory',
          slots: { customRender: 'subCategory' },
        },
        {
          title: this.$t('Description'),
          dataIndex: 'description',
          key: 'description',
          slots: { customRender: 'description' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
      selectedFacility: {},
    };
  },
  computed: {
    ...mapState('properties', ['property', 'loading']),
  },
  watch: {
    $route(route) {
      if (route.name === 'property-facilities') {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
    this.getPartnerList();
  },
  methods: {
    getPartnerList() {
      this.$store.dispatch('partners/names').then((res) => {
        this.partnerList = res.list;
      });
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('facilities/list', {
          propertyId: this.property.newPropertyCode,
        })
        .then((resp) => {
          this.list = resp.list.map((p, index) => {
            p._id = index + 1;
            return p;
          });
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    updateStatus(status, record) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      // eslint-disable-next-line no-unused-vars
      const { _id, ...rest } = record;
      this.$store.dispatch('facilities/update', { id: record.id, isActive: status }).finally(() => {
        this.fetchData();
      });
    },
    // eslint-disable-next-line no-unused-vars
    rowClicked(record) {
      return {
        onClick: () => {
          this.selectedFacility = record;
          this.$refs.editModel.show();
        },
      };
    },
  },
};
</script>

<style scoped></style>
