<template>
  <div class="section">
    <div v-if="property" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Incidents') }}
          </h2>
        </div>
      </div>
      <div class="columns is-mobi">
        <div class="column">
          <SearchForm :property="property" @filter="fetchPropertyData" />
        </div>
        <div v-permission="['add-incident']" class="column is-narrow">
          <Modal>
            <template #default="{ hide }">
              <AddIncidentForm
                :property="property"
                @close="
                  () => {
                    hide();
                    fetchPropertyData();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add Incident') }}</a
              >
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #reportedTime="{ record }">
          {{ $filters.date(record.occurredAt) }}
        </template>
        <template #name="{ record }">
          {{ record.name }}
        </template>
        <template #description="{ record }">
          {{ record.description }}
        </template>
        <template #status="{ record }">
          {{ record.status }}
        </template>
        <template #insuranceStatus="{ record }">
          {{ record.insuranceStatus }}
        </template>
        <template #action="{ record }">
          <DeleteIncident
            v-permission="['modify-incident']"
            :record="record"
            @delete="fetchPropertyData"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Incidents": "Incidents",
"Add Incident": "Add Incident",
"Reported Time": "Reported Time",
"Incident Name": "Incident Name",
"Status": "Status",
"Insurance Status": "Insurance Status",
},
"ja": {
"Incidents": "重大な設備不備",
"Add Incident": "重大な設備不備を追加",
"Reported Time": "報告時間",
"Incident Name": "重大な設備不備名",
"Status": "ステータス",
"Insurance Status": "保険ステータス",
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import SearchForm from '@/views/incidents/components/SearchForm';
import AddIncidentForm from '@/views/properties/components/AddIncidentForm';
import DeleteIncident from '@/views/incidents/components/DeleteIncident';
import list from '@/views/incidents/mixins/list';

export default {
  name: 'PropertyIncident',
  components: {
    Modal,
    SearchForm,
    AddIncidentForm,
    DeleteIncident,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Reported Time'),
          dataIndex: 'reportedTime',
          slots: { customRender: 'reportedTime' },
        },
        {
          title: this.$t('Incident Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Status'),
          key: 'status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Insurance Status'),
          key: 'insuranceStatus',
          dataIndex: 'insuranceStatus',
          slots: { customRender: 'insuranceStatus' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    ...mapState('properties', ['property', 'loading']),
  },
  created() {
    this.fetchPropertyData();
  },
  methods: {
    fetchPropertyData() {
      return this.fetchData({ propertyId: this.property.newPropertyCode });
    },
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchPropertyData();
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({ name: 'incidents-edit', params: { id: record.id } });
        },
      };
    },
  },
};
</script>

<style scoped></style>
