<template>
  <div class="section">
    <div v-if="property" class="container">
      <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
        {{ $t('Property Details') }}
      </h2>
      <div class="columns is-large-gap">
        <div class="column is-6">
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Address') }}
              </div>
            </div>
            <div v-permission="['modify-property']" class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditPropertyAddressForm
                      :value="property"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Postal Code') }}
                </div>
                <div>{{ property.postalCode }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Prefecture') }}
                </div>
                <div>{{ $t(property.prefecture) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Latitude') }}
                </div>
                <div>{{ property.latitude }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Longitude') }}
                </div>
                <div>{{ property.longitude }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Address') }}
                </div>
                <div>{{ property.address }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Building') }}
              </div>
            </div>
            <div v-permission="['modify-property']" class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditPropertyBuildingForm
                      :value="property"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div v-role="ROLES.INTERNAL" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Total Floor Area (square meters)') }}
                </div>
                <div>{{ property.building.totalFloorArea }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Number of Rooms') }}
                </div>
                <div>{{ property.building.numberOfRooms }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Overall Layout') }}
                </div>
                <div v-if="property.building.overallLayout">
                  <a
                    :href="$filters.imageUrl(property.building.overallLayout)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{{ $t('show image') }}</a>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Nearby Landmarks') }}
                </div>
                <div>{{ property.building.nearbyLandmarks }}</div>
              </div>
            </div>
            <div v-role="ROLES.INTERNAL" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Access from Nearby Station') }}
                </div>
                <div>{{ property.building.accessFromNearbyStation }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Number of Car Park Slots') }}
                </div>
                <div>{{ property.building.numberOfCarParkSlots }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Car Park Location') }}
                </div>
                <div>{{ property.building.carParkLocation }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Car Park Rules') }}
                </div>
                <div>{{ property.building.carParkRules }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Car Park Location Reference') }}
                </div>
                <div>{{ property.building.carParkLocationReference }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Places where guests are not allowed to use') }}
                </div>
                <div>{{ property.building.placesNotAllowedToUse }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Notes') }}
                </div>
                <div>{{ property.building.notes }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Property info') }}
              </div>
            </div>
            <div v-permission="['modify-property']" class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditPropertyInfoForm
                      :value="property"
                      :anshin-accounts="anshinAccounts"
                      :anshin-properties="anshinProperties"
                      :neppan-accounts="neppanAccounts"
                      @anshinAccountChange="getAnshinProperties"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('New Property Code') }}
                </div>
                <div>{{ property.newPropertyCode }}</div>
              </div>
            </div>
            <div v-if="property.anshinPropertyId" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Anshin Property ID') }}
                </div>
                <div>{{ property.anshinPropertyId }}</div>
              </div>
            </div>
            <div v-if="property.neppanPropertyCode" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Neppan Property Code') }}
                </div>
                <div>{{ property.neppanPropertyCode }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Main Brand') }}
                </div>
                <div>{{ property.mainBrand }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property Abbreviation') }}
                </div>
                <div>{{ property.abbreviation }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sub Brand') }}
                </div>
                <div>{{ property.subBrand }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property Name') }}
                </div>
                <div>{{ property.name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Tablet') }}
                </div>
                <div>{{ property.tablet.tablet }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property Name (English)') }}
                </div>
                <div>{{ property.nameEn }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Tablet Mode') }}
                </div>
                <div>{{ property.tablet.mode }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property Name (Japanese)') }}
                </div>
                <div>{{ property.nameKana }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Tablet Location') }}
                </div>
                <div>{{ property.tablet.location }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property Size') }}
                </div>
                <div>{{ property.size }}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-role="ROLES.INTERNAL" class="column is-6">
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Policies') }}
              </div>
            </div>
            <div v-permission="['modify-property']" class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditPropertyPoliciesForm
                      :value="property"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div
            v-role="ROLES.INTERNAL"
            class="columns columns-table is-gapless is-multiline is-size-7 m-b-0"
          >
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('How to checkin') }}
                </div>
                <div>{{ policies.howToCheckIn }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Checkin time') }}
                </div>
                <div>{{ policies.checkInTime }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Checkout time') }}
                </div>
                <div>{{ policies.checkOutTime }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Cut off time') }}
                </div>
                <div>{{ policies.cutOffTime }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('CS tel') }}
                </div>
                <div>{{ policies.csPhone }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('CS email') }}
                </div>
                <div>{{ policies.csEmail }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Tax & License') }}
              </div>
            </div>
            <div v-permission="['modify-property']" class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditPropertyTaxLicenseForm
                      :value="property"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Accommodation tax') }}
                </div>
                <div>{{ taxLicense.accommodationTax }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Bath tax') }}
                </div>
                <div>{{ taxLicense.bathTax }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Business License Type') }}
                </div>
                <div>{{ taxLicense.businessLicenseType }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Business License Holder') }}
                </div>
                <div>{{ taxLicense.businessLicenseHolder }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Accommodation tax correspondent') }}
                </div>
                <div>{{ taxLicense.accommodationTaxCorrespondent }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Accommodation tax correspondent company') }}
                </div>
                <div>{{ taxLicense.accommodationTaxCorrespondentCompany }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Bath tax correspondent') }}
                </div>
                <div>{{ taxLicense.bathTaxCorrespondent }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Bath tax correspondent company') }}
                </div>
                <div>{{ taxLicense.bathTaxCorrespondentCompany }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Internal') }}
              </div>
            </div>
            <div v-permission="['modify-property']" class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditPropertyInternalForm
                      :value="property"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Physical key custodian') }}
                </div>
                <div>{{ internal.physicalKeyCustodian }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Physical key custodian company') }}
                </div>
                <div>{{ internal.physicalKeyCustodianCompany }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Construction Category') }}
                </div>
                <div>{{ $t(internal.constructionCategory || '') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Operation Status') }}
                </div>
                <div>{{ $t(internal.operationStatus || '') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Contract Status') }}
                </div>
                <div>{{ $t(internal.contractStatus || '') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Reservation Start Date') }}
                </div>
                <div>
                  {{ $filters.date(internal.reservationStartDate, { format: 'YYYY-MM-DD' }) }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Contract End Date') }}
                </div>
                <div>{{ $filters.date(internal.contractEndDate, { format: 'YYYY-MM-DD' }) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Contract Type') }}
                </div>
                <div>{{ $t(internal.contractType || '') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Sales PIC') }}
                </div>
                <div>{{ internal.salesPic }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Opening PIC') }}
                </div>
                <div>{{ internal.openingPic }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property Manager') }}
                </div>
                <div>{{ getUserName(property.propertyManager) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Revenue Manager') }}
                </div>
                <div>{{ getUserName(property.revenueManager) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Design Company') }}
                </div>
                <div>{{ internal.designCompany }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Construction Company') }}
                </div>
                <div>{{ internal.constructionCompany }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Image URL') }}
                </div>
                <div>{{ internal.imageUrl }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Channel Manager') }}
                </div>
                <div>{{ internal.channelManager }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Cleaning Fee') }}
                </div>
                <div>{{ internal.cleaningFee }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Currency') }}
                </div>
                <div>{{ internal.currency }}</div>
              </div>
            </div>
            <div class="column  is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Emergency Assistant Fee') }}
                </div>
                <div>{{ internal.emergencyAssistantFee }}</div>
              </div>
            </div>
            <div class="column  is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Timezone') }}
                </div>
                <div>{{ property.timezone }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/locales/components/prefectures.json"></i18n>
<i18n>
{
  "en": {
    "Property Details": "Property Details",
    "Property info": "Property info",
    "Edit": "Edit",
    "New Property Code": "New Property Code",
    "Anshin Property ID": "Anshin Property ID",
    "Neppan Property Code": "Neppan Property Code",
    "Main Brand": "Main Brand",
    "Property Abbreviation": "Property Abbreviation",
    "Sub Brand": "Sub Brand",
    "Property Size": "Property Size",
    "Small": "Small",
    "Large": "Large",
    "Property Name": "Property Name",
    "Tablet": "Tablet",
    "Property Name (English)": "Property Name (English)",
    "Tablet Mode": "Tablet Mode",
    "Property Name (Japanese)": "Property Name (Japanese)",
    "Tablet Location": "Tablet Location",
    "Address": "Address",
    "Edit": "Edit",
    "Postal Code": "Postal Code",
    "Prefecture": "Prefecture",
    "Address": "Address",
    "Latitude": "Latitude",
    "Longitude": "Longitude",
    "Building": "Building",
    "Total Floor Area (square meters)": "Total Floor Area (square meters)",
    "Number of Rooms": "Number of Rooms",
    "Overall Layout": "Overall Layout",
    "show image": "show image",
    "Nearby Landmarks": "Nearby Landmarks",
    "Access from Nearby Station": "Access from Nearby Station",
    "Number of Car Park Slots": "Number of Car Park Slots",
    "Car Park Location": "Car Park Location",
    "Car Park Rules": "Car Park Rules",
    "Car Park Location Reference": "Car Park Location Reference",
    "Places where guests are not allowed to use": "Places where guests are not allowed to use",
    "Notes": "Notes",
    "Policies": "Policies",
    "How to checkin": "How to checkin",
    "Checkin time": "Checkin time",
    "Checkout time": "Checkout time",
    "Cut off time": "Cut off time",
    "Tax & License": "Tax & License",
    "Accommodation tax": "Accommodation tax",
    "Bath tax": "Bath tax",
    "Business License Type": "Business License Type",
    "Business License Holder": "Business License Holder",
    "Accommodation tax correspondent": "Accommodation tax correspondent",
    "Accommodation tax correspondent company": "Accommodation tax correspondent company",
    "Bath tax correspondent": "Bath tax correspondent",
    "Bath tax correspondent company": "Bath tax correspondent company",
    "Internal": "Internal",
    "Physical key custodian": "Physical key custodian",
    "Physical key custodian company": "Physical key custodian company",
    "Construction Category": "Construction Category",
    "Operation Status": "Operation Status",
    "Contract Status": "Contract Status",
    "Reservation Start Date": "Reservation Start Date",
    "Contract End Date": "Contract End Date",
    "Contract Type": "Contract Type",
    "Sales PIC": "Sales PIC",
    "Opening PIC": "Opening PIC",
    "Property Manager": "Property Manager",
    "Revenue Manager": "Revenue Manager",
    "Design Company": "Design Company",
    "Construction Company": "Construction Company",
    "Image URL": "Image URL",
    "Channel Manager": "Channel Manager",
    "Cleaning Fee": "Cleaning Fee",
    "Emergency Assistant Fee": "Emergency Assistant Fee",
    "Currency": "Currency",
    "CS tel": "CS tel",
    "CS email": "CS email"
  },
  "ja": {
    "Property Details": "物件詳細",
    "Property info": "物件管理情報",
    "Edit": "編集",
    "New Property Code": "新規物件コード",
    "Anshin Property ID": "AnshinプロパティID",
    "Neppan Property Code": "Neppanプロパティコード",
    "Main Brand": "メインブランド",
    "Property Abbreviation": "物件略称",
    "Sub Brand": "サブブランド",
    "Property Size": "プロパティサイズ",
    "Small": "小さな",
    "Large": "大きい",
    "Property Name": "物件正式名称",
    "Tablet": "タブレット",
    "Property Name (English)": "物件正式名称 (英語)",
    "Tablet Mode": "タブレットモード",
    "Property Name (Japanese)": "物件正式名称 (フリガナ)",
    "Tablet Location": "タブレット設置場所",
    "Address": "施設住所",
    "Edit": "編集",
    "Postal Code": "施設郵便番号",
    "Prefecture": "都道府県",
    "Address": "施設住所",
    "Latitude": "緯度",
    "Longitude": "経度",
    "Building": "施設情報",
    "Total Floor Area (square meters)": "延床面積 (平米数)",
    "Number of Rooms": "部屋数",
    "Overall Layout": "全体配置図",
    "show image": "写真を表示",
    "Nearby Landmarks": "近くの目印",
    "Access from Nearby Station": "最寄り駅・Access",
    "Number of Car Park Slots": "駐車可能台数",
    "Car Park Location": "駐車場位置情報",
    "Car Park Rules": "駐車場ルール",
    "Car Park Location Reference": "駐車場位置閲覧",
    "Places where guests are not allowed to use": "ゲスト使用不可能場所",
    "Notes": "注意事項",
    "Policies": "ポリシー",
    "How to checkin": "チェックイン方法",
    "Checkin time": "チェックイン時刻",
    "Checkout time": "チェックアウト時刻",
    "Cut off time": "手仕舞い時刻",
    "Tax & License": "税金と免許",
    "Accommodation tax": "宿泊税",
    "Bath tax": "入湯税",
    "Business License Type": "営業許可種別",
    "Business License Holder": "営業許可証保有者",
    "Accommodation tax correspondent": "宿泊税担当者",
    "Accommodation tax correspondent company": "宿泊税担当業者",
    "Bath tax correspondent": "入湯税担当者",
    "Bath tax correspondent company": "入湯税担当業者",
    "Internal": "その他運用情報",
    "Physical key custodian": "物理キー保有者",
    "Physical key custodian company": "物理鍵保管主体",
    "Construction Category": "築区分",
    "Operation Status": "稼働状況",
    "Contract Status": "契約状態",
    "Reservation Start Date": "予約受付開始日",
    "Contract End Date": "契約完了日時",
    "Contract Type": "契約タイプ",
    "Sales PIC": "事業創造担当者",
    "Opening PIC": "開発担当者",
    "Property Manager": "運用担当者",
    "Revenue Manager": "販売推進担当者",
    "Design Company": "設計会社",
    "Construction Company": "施工会社",
    "Image URL": "写真URL",
    "Channel Manager": "チャネルマネージャー",
    "Cleaning Fee": "清掃費",
    "Emergency Assistant Fee": "立替金精算",
    "Currency": "通貨",
    "CS tel": "CS tel",
    "CS email": "CS email"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import { getFullname } from '@/utils/users';
import roles from '@/components/mixins/roles';
import IconEdit from '@/components/icons/IconEdit';
import EditPropertyInfoForm from '@/views/properties/components/EditPropertyInfoForm';
import EditPropertyAddressForm from '@/views/properties/components/EditPropertyAddressForm';
import EditPropertyBuildingForm from '@/views/properties/components/EditPropertyBuildingForm';
import EditPropertyPoliciesForm from '@/views/properties/components/EditPropertyPoliciesForm';
import EditPropertyTaxLicenseForm from '@/views/properties/components/EditPropertyTaxLicenseForm';
import EditPropertyInternalForm from '@/views/properties/components/EditPropertyInternalForm';

export default {
  name: 'PropertyProperty',
  components: {
    EditPropertyInternalForm,
    EditPropertyTaxLicenseForm,
    EditPropertyPoliciesForm,
    EditPropertyBuildingForm,
    EditPropertyAddressForm,
    EditPropertyInfoForm,
    Modal,
    IconEdit,
  },
  mixins: [roles],
  data() {
    return {
      anshinProperties: [],
      anshinAccounts: [],
      neppanAccounts: [],
      submitting: false,
    };
  },
  computed: {
    ...mapState('properties', ['property', 'loading']),
    policies() {
      return this.property.policies || {};
    },
    taxLicense() {
      return this.property.taxLicense || {};
    },
    internal() {
      return this.property.internal || {};
    },
  },
  created() {
    this.$store.dispatch('properties/listAnshinAccounts').then((res) => {
      this.anshinAccounts = res.list;
    });
    this.$store.dispatch('properties/listNeppanAccounts', { availableOnly: true, includeId: this.property.neppanPropertyCode }).then((res) => {
      this.neppanAccounts = res.list;
    });
    if (this.property.anshinAccountId) {
      this.getAnshinProperties();
    }
  },
  methods: {
    getUserName(user) {
      if (!user) {
        return '';
      }

      return getFullname({
        fname: user.firstName,
        lname: user.lastName,
      });
    },
    asyncData() {
      this.$store.dispatch('properties/get', { id: this.$route.params.id });
    },
    async getAnshinProperties(val) {
      const payload = {
        params: {
          availableOnly: true,
          includeId: this.property.anshinPropertyId,
        },
        anshinAccountId: val || this.property.anshinAccountId,
      };

      if (!payload.anshinAccountId) {
        return;
      }

      await this.$store.dispatch('properties/listAnshinGroups', payload)
        .then((res) => {
          this.anshinProperties = res.list;
          this.isFetchingAnshin = false;
        });
    },
  },
};
</script>

<style scoped></style>
